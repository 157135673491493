.element-input {
    display: flex;
    align-items: center;
}

.element-input .icon {
    position: relative;
    opacity: .5;
}

 .react-datepicker {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    color: var(--font-color);
    border: none;
    background-color: var(--window-bg);
}

.react-datepicker__header {
    background-color: #373E4B;
    border: none;
    color: var(--font-color);
}

.react-datepicker__month-wrapper {
    margin-bottom: 10px;
}

.react-datepicker__month-text {
    padding: 5px 0px 8px 0px;
    border: 1px solid rgba(255, 255, 255, 0);
}

.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--button-bg-blue);
    color: var(--font-color);
    font-weight: 400;
} 

.react-datepicker__day:not([aria-disabled=true]):hover, .react-datepicker__month-text:not([aria-disabled=true]):hover, .react-datepicker__quarter-text:not([aria-disabled=true]):hover, .react-datepicker__year-text:not([aria-disabled=true]):hover {
    background-color: transparent;
    border: 1px solid var(--button-bg-blue);

}

.react-datepicker__navigation-icon {
    margin-bottom: 7px;
}

.react-datepicker-popper .react-datepicker__triangle {
    stroke: none;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    fill: #525963;
    stroke: transparent !important;
}

.react-datepicker-popper svg * {
    color: #525963;
}

.react-datepicker__day {
    color: var(--font-color);
    border: 1px solid rgba(255, 255, 255, 0);

}

.react-datepicker__day-names, 
.react-datepicker__header__dropdown, 
.react-datepicker__current-month, 
.react-datepicker__day-name {
    color: var(--font-color) !important;
} 

.element-popup {
    display: inline-block;
    position: absolute;
    border-radius: 5px;
    background-color: var(--window-bg);
    transform: translateY(5px);
    border: 1px solid rgba(255, 255, 255, .1);
    z-index: 999999;
}

.element-popup .items .item {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 10px 20px;
    transition: border-color .2s ease-in-out;
}

.element-popup .items .item:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.element-popup .items .item:hover {
    background-color: rgba(255, 255, 255, .05);
}

.element-popup .items .item .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.element-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(2px);
    z-index: 999999999;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.element-modal-wrapper .modal-container {
    background-color: var(--window-bg);
    border-radius: 10px;
}

.element-modal-wrapper .header {
    padding: 5px 5px 5px 20px;
    background-color: rgba(255, 255, 255, .1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    border-radius: 10px 10px 0px 0px;
}

.element-modal-wrapper .header .title {
    width: 100%;
    font-weight: 600;
}

.element-modal-wrapper .header .close {
    text-align: right;
}

.element-modal-wrapper .header .close button {
    padding: 5px;
}

.element-modal-wrapper .header .close button:hover {
    background-color: rgba(255, 255, 255, .1); 
}

.element-modal-wrapper .content {
    padding: 20px;
}

.element-modal-wrapper .buttons {
    border-top: 1px solid rgba(255, 255, 255, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 20px;
}

.element-modal-wrapper .buttons button {
    width: initial;
    padding: 10px 20px;
}

.element-project {
    display: inline-flex;
    align-items: center;
    padding: 3px 5px;
    border-radius: 5px;
}

.element-project .icon {
    margin-right: 10px;
}

.element-project .status {
    font-size: 14px;
}

.element-error {
    color: var(--text-color-red);
    margin-top: 5px;
}

.element-print-user {
    display: flex;
    align-items: center;
}

.element-print-user .avatar {
    margin-right: 10px;
}

.element-print-user .avatar img {
    width: 28px;
    height: 28px;
    border-radius: 100%;
}

.element-print-user .name {
    line-height: 1.2;
}

.element-print-budget {
    display: flex;
    align-items: center;
}

.element-print-budget .icon {
    margin-left: 10px;
    opacity: 0.5;
}

.element-button-add-payment button {
    width: 60px;
}

.element-button-add-payment button:hover {
    background-color: rgba(255, 255, 255, .1); 
}

.element-project-more-button button {
    width: 60px;
}

.element-project-more-button button:hover {
    background-color: rgba(255, 255, 255, .1); 
}


.element-plan-of-payment {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 7px;
    border-radius: 5px;
    transition: all .2s ease-in-out
}

.element-plan-of-payment:hover {
    background-color: rgba(255, 255, 255, .1);
}

.element-plan-of-payment .val-flex {
    display: flex;
}

.element-plan-of-payment .value {
    width: 100%;
}

.element-plan-of-payment .icon {
    margin-right: 15px;
}

.element-plan-of-payment .val-icon {
    margin-right: 5px;
}

.element-plan-of-payment .val-text span {
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
}

.element-plan-of-payment .progress-container {
    margin-top: 5px;
    height: 3px;
}

.element-plan-of-payment .icon-animate {
    animation: myrotate 2s ease-in-out 0s infinite normal none;
}

.element-plan-of-payment .val-plan-payment {
    background-color: var(--button-bg-green);
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 3px 3px;
    border-radius: 3px;
}

@keyframes myrotate {

    0% {
        transform: rotate(0deg);
    }
    
    40% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }

}



.element-status-item {
    display: inline-flex;
    align-items: center;
    padding: 3px 7px;
    border-radius: 5px;
}

.element-status-item .icon {
    margin-right: 10px;
}

.element-status-item .text {
    font-size: 14px;
}

.element-print-month {
    display: flex;
    align-items: center;
}

.element-print-month .icon {
    margin-right: 7px;
}

.element-print-month .icon-time {
    margin-left: 15px;
}

.element-remove-payment button {
    display: flex;
    width: 60px;
    padding: 10px 15px;
    margin-left: 20px;
}

.element-remove-payment button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.period-copy-form .columns {
    display: grid;
    grid-template-columns: 5fr 2fr 5fr;
    align-items: center;
    justify-content: center;
}

.period-copy-form .icon {
    text-align: center;
}

.period-copy-form .headers {
    margin-bottom: 10px;
}

.element-button-remove-period button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.button-comment-payments button {
    padding: 7px;
    width: initial;
}

.button-comment-payments button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.element-comment-input .textarea input{
    margin-bottom: 20px;
}

.element-comment-input .button button {
    display: inline-flex !important;
    width: initial;
}

.element-pay-comments {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding-top: 30px;
}

.element-pay-comments .items {
    display: grid;
    grid-template-columns: 12fr;
    gap: 30px;
}

.element-pay-comments .items .item {
    display: flex;
}

.element-pay-comments .items .item .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.element-pay-comments .items .item .avatar {
    margin-right: 15px;
}

.element-pay-comments .items .item .text {
    background-color: rgba(255, 255, 255, .05);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.element-pay-comments .items .item .text .txt {
    line-height: 1.3;
}

.element-pay-comments .items .item .cont {
    width: 600px;
}

.element-pay-comments .items .item .date-pub {
    color: rgba(255, 255, 255, .5);
    margin-bottom: 10px;
}

.element-pay-comments .items .item .date-pub div {
    font-size: 14px;
}

.element-pay-comments .items .item .date-pub-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.element-pay-comments .items .item .date-pub-flex button {
    padding: 3px;
    border-radius: 3px;
}

.element-pay-comments .items .item .date-pub-flex button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.element-action-date {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.element-action-date-modal {
    position: absolute;
    background-color: var(--window-bg);
    padding: 10px;
    border-radius: 10px;
    transform: translateX(-80px) translateY(50px) ;
    width: 350px;
}

.element-action-date-modal .buttons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.element-action-date-modal .buttons button {
    background-color: rgba(255, 255, 255, .1);
    transition: none;
}

.element-action-date-modal .buttons button:hover {
    background-color: rgba(255, 255, 255, .2);
}

.element-action-date-modal .buttons .button-active button {
    background-color: rgba(0, 128, 0, .5);
}

.element-action-date-modal .comment-form {
    margin-top: 15px;
    width: 100%;
}

.element-action-date-modal .comment-form .avatar {
    margin-right: 20px;
}

.element-action-date-modal .comment-form .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.element-action-date-modal .comment-form {
    display: inline-flex;
}

.element-action-date-modal .comment-form .text-area {
    width: 100%;
}

.element-action-date-modal textarea {
    background-color: rgba(255, 255, 255, .05);
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: var(--font-color);
    width: 100%;
    height: 100px;
    resize: none;
    padding: 5px 10px;
    transition: none !important;
    height: 32px;
}

.element-action-date-modal .comment-form button {
    font-size: 14px;
    padding: 7px 10px;
    width: initial;
    margin-top: 15px;
}

.element-action-date-modal .comments {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.element-action-date-modal .comments .comment-item {
    display: flex;
}

.element-action-date-modal .comments .comment-item .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 100%
}

.element-action-date-modal .comments .comment-item .avatar {
    margin-right: 20px;
}

.element-action-date-modal .comments .comment-item .txt {
    background-color: rgba(255, 255, 255, .05);
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: var(--font-color);
    width: 100%;
    font-size: 14px;
    line-height: 1.3 !important;
}

.element-action-date-modal .comments .comment-item .txt-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.element-action-date-modal .comments .comment-item .txt .del button {
    padding: 3px;
    width: initial;
    display: inline-flex;
    border-radius: 3px;
}

.element-action-date-modal .comments .comment-item .txt .del button:hover {
    background-color: rgba(255, 255, 255, .05);
}

.element-action-date-modal .comments .comment-item .txt .dat {
    opacity: .5;
}

.element-action-date-modal .comments .comment-item .txt .cont {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 10px;
}

.element-action-date-modal .element-print-month .value {
    font-size: 14px;
}


