* {
    padding: 0;
    margin: 0;
    font-size: var(--font-size);
    font-weight: 400;
    line-height: 1;
    box-sizing: border-box;    
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    
    
    --font-size: 16px;
    --font-color: #D2D7DF;
    --background-color: #161B22;

    --link-color: #478be6;
    --link-color-hover: #D2D7DF;

    --input-bg-color: rgba(255, 255, 255, .07);
    --input-border-color: rgba(255, 255, 255, .2);
    --button-bg-green: #347d39;
    --button-bg-green-hover: #3b8640;
    --button-bg-blue: #4184e4;
    --button-bg-blue-hover: #539bf5;
    --button-bg-gray: rgba(255, 255, 255, .2);
    --button-bg-gray-hover: rgba(255, 255, 255, .25);
    --button-bg-red: #ea5c53;
    --button-bg-red-hover: #ca4f46;

    --window-bg: #242F3D;
    --window-border: rgba(255, 255, 255, 0);

    --text-color-red: #ea5c53;
    --text-color-orange: #ffc107;

    --top-bar-height: 60px;
    --side-bar-width: 300px;
}

body {
    background-color: var(--background-color);
    color: var(--font-color);
}

a {
    color: var(--link-color);
    text-decoration: none;
}


a:hover {
    text-decoration: underline;
}

input {
    border: none;
    background-color: var(--input-bg-color);
    border-radius: 7px;
    padding: 9px 16px;
    font-size: var(--font-size);
    width: 100%;
    outline: 2px solid rgba(65, 132, 228, 0);
    transition: all .2s ease-in-out;
    color: var(--font-color);
}

textarea {
    transition: all .2s ease-in-out;
    outline: 2px solid rgba(65, 132, 228, 0);
    line-height: 1.3;
}

input:focus,
textarea:focus {
    outline: 2px solid rgba(65, 132, 228, 1);
}

button {
    width: 100%;
    border: none;
    /* height: var(--input-height); */
    padding: 12px 20px;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: transparent;
    color: var(--font-color);
}

button:active {
    transform: scale(0.95);
}

.btn-green {
    background-color: var(--button-bg-green);
}

.btn-green:hover {
    background-color: var(--button-bg-green-hover);
}

.btn-blue {
    background-color: var(--button-bg-blue);
}

.btn-blue:hover {
    background-color: var(--button-bg-blue-hover);
}

.btn-gray {
    background-color: var(--button-bg-gray);
}

.btn-gray:hover {
    background-color: var(--button-bg-gray-hover);
}

.btn-red {
    background-color: var(--button-bg-red);
}

.btn-red:hover {
    background-color: var(--button-bg-red-hover);
}

.btn-transparent {
    background-color: rgba(255, 255, 255, .1);
}

.btn-transparent:hover {
    background-color: rgba(255, 255, 255, .15);
}

.btn-inline {
    display: inline-flex;
    width: 70px;
}

.btn-inline:not(:last-child) {
    margin-right: 20px;
}

.icon-red {
    color: var(--text-color-red) !important;
}



.display-none {
    display: none !important;
}

.form-wrapper {
   display: flex;
   align-items: center;
   height: 100vh;
   justify-content: center;
}

.form-wrapper .form-window {
    background-color: rgba(44, 49, 59, .5);
    padding: 30px;
    border-radius: 7px;
    width: 450px;
}

.form-wrapper label {
    display: block;
    margin-bottom: 15px;
}

.form-wrapper .form-error {
    margin-top: 10px;
    color: var(--text-color-red);
}

.form-wrapper .form-error div {
    color: var(--text-color-red);
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-wrapper .form-item:not(:last-child) {
    margin-bottom: 40px;
}

.form-wrapper .form-buttons {
    display: flex;
    align-items: center;
    gap: 30px;
}

.form-wrapper .form-buttons .item {
    width: 100%;
}

.form-wrapper .form-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
    font-size: 30px;
    font-weight: 900;
}

.form-wrapper .form-bottom {
    margin-top: 50px;
    font-size: 14px;
    text-align: center;
    color: rgba(255, 255, 255, .5);
}

.form-wrapper .form-bottom a {

    font-size: 14px;
}

.form-wrapper .element-input .input{
    width: 100%;
}

.access-denied-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.access-denied-wrapper .text {
    font-size: 120px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    opacity: .2;
}

.access-denied-wrapper .link {
    font-size: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.access-denied-wrapper .link a {
    font-size: 20px;

}

.access-denied-wrapper .link-text {
    opacity: .3;
}

.top-bar {
    height: var(--top-bar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: var(--background-color);
    background-color: var(--window-bg);
}

.top-bar .left {
    display: flex;
    align-items: center;
    gap: 50px;
}

.top-bar .left .button button {
    background-color: transparent;
    /* border: 1px solid rgba(255, 255, 255, .2); */
    padding: 0;
    width: 32px;
    height: 32px;
}

.top-bar .right button {
    padding: 0;
    width: 32px;
    height: 32px;
    margin-left: 30px;
}

.top-bar .right .search {
    display: flex;
    align-self: center; 
}

.top-bar .right .search .icon {
    display: flex;
    align-items: center;
    position: relative;
    left: 30px;
}

.top-bar .right .search .input input {
    padding-left: 40px;
}

.top-bar .right button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.top-bar .right {
    display: flex;
    align-items: center;
}

.top-bar .left .user-area {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.top-bar .left .user-area:hover .name-icon {
    background-color: rgba(255, 255, 255, .075);
    /* border: 1px solid rgba(255, 255, 255, .075); */
}

.top-bar .left .user-area .name-icon-back {
    background-color: rgba(255, 255, 255, .075);
}

.top-bar .left .user-area .name-icon {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color .1s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0);
}

.top-bar .left .avatar {
    margin-right: 10px;
}

.top-bar .left .avatar img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.top-bar .left .name {
    font-weight: 600;
}

.top-bar .user-area-menu {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--window-bg);
    border-radius: 5px;
    /* border: 1px solid rgba(255, 255, 255, .2); */
}

.top-bar .user-area-menu .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 7px 7px 15px;
    background-color: rgba(255, 255, 255, .075);
    border-radius: 5px 5px 0px 0px;
}

.top-bar .user-area-menu .top .title {
    font-weight: 600;
    font-size: 14px;
}

.top-bar .user-area-menu .content {
    padding: 15px;
    font-size: 14px;
}

.top-bar .user-area-menu .button {
    padding: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    transition: background-color .1s ease-in-out;
    cursor: pointer;
}

.top-bar .user-area-menu .button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.top-bar .user-area-menu .button:active {
    transform: scale(0.93);
}

.top-bar .left .icon {
    transition: all .1s ease-in-out;
}

.top-bar .left .icon-translate {
    transform: rotate(180deg);
}

.side-bar {
    /* background-color: var(--window-bg); */
    background-color: #1b2430;
    width: var(--side-bar-width);
    height: calc(100% - var(--top-bar-height) - 0px);
    position: fixed;
    z-index: 999999;
    top: var(--top-bar-height);
    transition: left .2s ease-in-out;
    padding: 30px;
    border-right: 1px solid rgba(255, 255, 255, .1);

}

.side-bar-left {
    left: 0;
}

.side-bar-left-no {
    left: calc(-1 * var(--side-bar-width));
}

.side-bar .menu-items {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.side-bar .menu-items .active .item {
    background-color: rgba(255, 255, 255, .05);
}

.side-bar .menu-items .item {
    display: flex;
    align-items: center;
    width: 100%;
    transition: background-color .1s ease-in-out;
    padding: 15px 10px;
    border-radius: 5px;
}

.side-bar .menu-items .item .icon {
    margin-right: 15px;
    opacity: 0.5;;
}

.side-bar .menu-items .item .text {
    font-size: 16px;
    font-weight: 600;
}

.side-bar .menu-items .item:hover {
    background-color: rgba(255, 255, 255, .1);
}

.side-bar .menu-items a {
    color: var(--font-color);
}

.side-bar .menu-items a:hover {
    text-decoration: none;
}

.side-bar .line {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin: 30px 0px;
}

.debitorka-widget {
    display: inline-flex;
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.debitorka-widget:hover {
    background-color: rgba(255, 255, 255, .1);
    
}

.debitorka-widget .progress-container {
    margin-top: 7px;
}

.debitorka-widget .header {
    font-size: 12px;
    font-weight: 900;
}

.debitorka-widget .header span {
    font-size: 12px;
    font-weight: 400;
}

.debitorka-widget .right {
    margin-left: 25px;
}

.progress-container {
    background-color: rgba(255, 255, 255, .2);
    height: 7px;
    border-radius: 3px;
}

.progress-container .progress-value {
    border-radius: 3px;
    height: 100%;
    transition: all .5s ease-in-out;
}


.work-area {
    padding: calc(var(--top-bar-height) + 30px) 30px 30px 30px;
}

.work-area .header {
    font-size: 24px;
    font-weight: 600;
}

.work-area .top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding-bottom: 30px;
}

.work-area .top-area .left {
    display: flex;
    align-items: center;
    gap: 50px;
}

.work-area .top-area .refresh-button {
    background-color: rgba(255, 255, 255, .05); 
}

.work-area .top-area .refresh-button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.work-area .content-area {
    margin: 30px 0px;
}

.add-project-form .form-item:not(:last-child){
    margin-bottom: 20px;
}

.add-project-form .form-item input {
    width: 400px !important;
}

.add-project-form .form-item label {
    margin-bottom: 10px;
    display: block;
}

.add-project-form .form-item .label-item {
    background-color: var(--input-bg-color);
    border-radius: 7px;
    padding: 9px 16px;
}

.add-project-form .form-item .label-passive {
    opacity: .5;
}

.add-project-form .form-item-grid {
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 30px;
}

.add-project-form .form-item-grid label {
    display: block;
    margin-bottom: 10px;
}

.add-project-form .form-item-grid:not(:last-child) {
    margin-bottom: 30px;
}

.add-project-form .form-item-grid input {
    width: 400px !important;
}

.add-project-form .payments-history .items {
    display: grid;
    grid-template-columns: 12fr;
    gap: 10px;
}

.add-project-form .payments-history .item {
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    background-color: rgba(255, 255, 255, .05);
    padding: 5px 10px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}

.add-project-form .payments-history .item:hover {
    background-color: rgba(255, 255, 255, .1);
}

.add-project-form .payments-history label {
    margin-bottom: 10px;
    display: block;
}

.add-project-form .payments-history .item-header {
    background-color: rgba(255, 255, 255, 0);
    margin-top: 15px;
}

.add-project-form .payments-history .item-header div {
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
}

.add-project-form .payments-history .item-header:hover {
    background-color: rgba(255, 255, 255, 0);
}

.add-project-form .payments-history .nothing {
    color: rgba(255, 255, 255, .3);
}

.period-table {
    display: grid;
    gap: 10px;
}

.period-table .head {
    margin-bottom: 10px;
}

.period-table .period-table-item {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background-color: rgba(255, 255, 255, .05);
    padding: 5px 10px;
    border-radius: 5px;
    align-items: center;
    transition: all .2s ease-in-out;
    gap: 30px;
}

.period-table .period-table-item-remove {
    grid-template-columns: repeat(4, 1fr);
}

.period-table .period-table-item:hover {
    background-color: rgba(255, 255, 255, .1);
}

.period-table .period-table-item-header {
    background-color: transparent;
    color: rgba(255, 255, 255, .5);
}

.period-table .period-table-item .table-buttons {
    display: flex;
    align-items: center;
}

.period-no-project div {
    text-align: center;
}

.period-no-project .image {
    display: inline-block;
    width: 250px;
    opacity: .3;
    margin-top: 50px;
}

.period-no-project .text {
    font-size: 20px;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, .3);
}

.period-no-project .text a {
    font-size: 20px;
    cursor: pointer;
} 

.element-add-pay-comment .form-item:not(:last-child) {
    margin-bottom: 30px;
}

.element-add-pay-comment label {
    display: block;
    margin-bottom: 10px;
}

.element-comment-input {
    display: flex;
}

.element-comment-input .avatar {
    margin-right: 20px;
}

.element-comment-input .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.element-comment-input .textarea {
    width: 600px;
}

.element-comment-input .textarea textarea {
    background-color: rgba(255, 255, 255, .05);
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: var(--font-color);
    width: 100%;
    height: 100px;
    resize: none;
}

.element-comment-input .date-picker-area {
    margin-top: 20px;
}

.element-comment-input .textarea .react-datepicker-wrapper,
.element-comment-input .textarea .react-datepicker__input-container {
    width: 100% !important;
}

.element-comment-input .textarea input {
    width: 100% !important;
}

.element-comment-input .button {
    margin-top: 10px;
}

.table-projects .items {
    display: grid;
    grid-template-columns: 12fr;
    gap: 10px;
}

.table-projects .items .item {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, .05);
    border-radius: 5px;
    transition: all .2s ease-in-out;
    align-items: center;
}

.table-projects .items .item:hover {
    background-color: rgba(255, 255, 255, .1);
}

.table-projects .items .item-head {
    background-color: transparent;
}

.table-projects .items .item-head:hover {
    background-color: transparent;
}

.table-projects .items .item-head div {
    opacity: .5;
}

.table-projects .items .item .remove-button {
    display: inline-flex;
    width: 35px;
    padding: 5px;
}

.table-projects .items .item .remove-button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.table-projects .txt {
    margin-bottom: 20px;
}

.interactions-table .items {
    display: grid;
    grid-template-columns: 12fr;
    gap: 5px;
    align-items: center;
}

.interactions-table .items .item {
    display: flex;
    gap: 5px;
}

.interactions-table .items .item .date {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .15);
    border-radius: 2px;
    /* transition: transform .05s ease-in-out; */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.interactions-table .items .item .date div {
    line-height: .7;
}

.interactions-table .items .item-header .date div {
    line-height: 1;
}

.interactions-table .items .item .date:hover {
    background-color: rgba(0, 128, 0, .5);
}

.interactions-table .items .item-header .date:hover {
    background-color: transparent;
}

.interactions-table .items .item .date-now {
    background-color: rgba(0, 128, 0, .3);
}

.interactions-table .items .item .date-present {
    background-color: rgba(255, 255, 255, .075);
}

.interactions-table .items .item-header .date {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0);
}

.interactions-table .items .item-header .date .digit {
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, .5);
}

.interactions-table .items .item-header .date .week {
    font-size: 10px;
    text-align: center;
    color: rgba(255, 255, 255, .3);
}


.interactions-table .items .item .project {
    min-width: 200px;
    background-color: transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.interactions-table .items .item .manager {
    background-color: transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
    min-width: 40px;
}

.interactions-table .items .item .manager img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.interactions-table .items .item-header .project {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: rgba(255, 255, 255, .5);
    font-size: 12px;
}

.interactions-table .items .item-header .manager {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: rgba(255, 255, 255, .5);
    font-size: 12px;  
}

.interactions-table .items .item {
    padding: 3px 5px;
    border-radius: 3px;
}

.interactions-table .items .item:hover {
    background-color: rgba(0, 128, 0, .2);
}

.interactions-table .items .item-header:hover {
    background-color: transparent;
}


